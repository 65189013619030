import { defineNuxtRouteMiddleware, navigateTo } from "nuxt/app";

/**
 * Checks if the provided string is a valid UUID (v4).
 * @param id - The string to validate.
 * @returns true if valid UUID, false otherwise.
 */
function isValidUUID(id: string): boolean {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(id);
}

// Define Nuxt Route Middleware
export default defineNuxtRouteMiddleware((to) => {
  const rawId = to.params.id;
  const id = Array.isArray(rawId) ? rawId[0] : rawId;

  // Validate ID — redirect if invalid
  if (typeof id !== "string" || !isValidUUID(id)) {
    return showError({ statusCode: 404, statusMessage: "Invalid receipt id" });
  }
});
